<template>
  <jc-form ref="form" class="jc-datashow-list jc-datashow-list-inline">
    <jc-row :gutter="20">
      <jc-col :span="8">
        <jc-form-item :label="siteData && siteData.appServiceType === 'app' ? 'APP名称' : '网站名称：'">
          <p>{{ siteData && siteData.siteName | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item :label="siteData && siteData.appServiceType === 'app' ? 'APP负责人' : '网站负责人：'">
          <p>{{ siteData && siteData.ownerName | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8" v-if="siteData && siteData.appServiceType === 'web'">
        <jc-form-item label="网站域名：">
          <p>
            {{ domainData.length && domainData.map(item => item.domain).join(',') | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8" v-if="siteData && siteData.appServiceType === 'web'">
        <jc-form-item label="网站首页 URL：">
          <p>
            {{ domainData.length && domainData[0].homeUrl | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>

      <jc-col :span="8" v-if="siteData && siteData.appServiceType === 'app'">
        <jc-form-item label="APP是否提供SDK服务：">
          <p>
            {{ siteData.appInfoVo && siteData.appInfoVo.sdkOfferFlag === 'YES' ? '是' : '否' }}
          </p>
        </jc-form-item>
      </jc-col>

      <jc-col :span="8" v-if="siteData && siteData.appServiceType === 'app'">
        <jc-form-item label="使用三方SDK：">
          <p>
            {{
              siteData.appInfoVo.useSdkInfoList &&
              siteData.appInfoVo.useSdkInfoList.length > 0 &&
              siteData.appInfoVo.useSdkInfoList[0].sdkCsId
                ? '是'
                : '否'
            }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="20" v-if="siteData && siteData.appServiceType === 'app'">
        <div></div>
      </jc-col>

      <template v-if="siteData && siteData.appServiceType === 'app'">
        <template v-for="(useSdkInfo, useSdkInfoIndex) in siteData.appInfoVo.useSdkInfoList">
          <jc-col :span="8" :key="'cs' + useSdkInfoIndex" v-if="useSdkInfo.sdkCsId">
            <jc-form-item :label="'SDK服务商' + (useSdkInfoIndex + 1)">
              <p>
                {{ getCsName(useSdkInfo.sdkCsId) }}
              </p>
            </jc-form-item>
          </jc-col>
          <jc-col :span="12" :key="'lx' + useSdkInfoIndex" v-if="useSdkInfo.sdkFwlxId">
            <jc-form-item :label="'SDK服务类型' + (useSdkInfoIndex + 1)" v-if="useSdkInfo.sdkCsId">
              <p>
                {{ useSdkInfo.sdkFwlxId.map(x => getFwlxName(x)).join(',') }}
              </p>
            </jc-form-item>
          </jc-col>
        </template>
      </template>

      <template v-if="siteData && siteData.appServiceType === 'app'">
        <template v-for="(pt, ptIndex) in appPtList">
          <jc-col :span="20" :key="ptIndex">
            <div class="paltform-type">平台类型{{ ptIndex + 1 }}：{{ getPtName(pt) }}</div>
          </jc-col>

          <template v-for="(ptInfoItem, ptInfoItemIndex) in pt.ptInfo">
            <jc-col :span="20" :key="'ptInfo' + ptInfoItemIndex">
              <div>平台特征值{{ ptInfoItemIndex + 1 }}</div>
            </jc-col>
            <jc-col :span="8" :key="'ptInfo.bm' + ptInfoItemIndex" style="padding-left: 100px">
              <jc-form-item label="APP包名：" v-if="pt.type === 'azPt'">
                <p>{{ ptInfoItem.appBm }}</p>
              </jc-form-item>
              <jc-form-item label="APP包名：" v-if="pt.type === 'hmPt'">
                <p>{{ ptInfoItem.hmBm }}</p>
              </jc-form-item>
              <jc-form-item label="APP包名：" v-if="pt.type === 'bbPt'">
                <p>{{ ptInfoItem.appBmbb }}</p>
              </jc-form-item>
              <jc-form-item label="bundleId:" v-if="pt.type === 'iosPt'">
                <p>{{ ptInfoItem.bundleId }}</p>
              </jc-form-item>
              <jc-form-item label="linux进程名称:" v-if="pt.type === 'linuxPt'">
                <p>{{ ptInfoItem.processName }}</p>
              </jc-form-item>
              <jc-form-item label="APP包名：" v-if="!pt.type">
                <p>{{ ptInfoItem.ptBm }}</p>
              </jc-form-item>
            </jc-col>

            <jc-col :span="8" :key="'ptInfo.key' + ptInfoItemIndex">
              <jc-form-item label="公钥：">
                <p>{{ ptInfoItem.key | limiteStr(50) }}</p>
              </jc-form-item>
            </jc-col>

            <jc-col :span="8" :key="'ptInfo.md5Key' + ptInfoItemIndex">
              <jc-form-item label="MD5签名值：">
                <p>{{ ptInfoItem.md5Key | limiteStr(50) }}</p>
              </jc-form-item>
            </jc-col>
          </template>

          <jc-col :span="20" :key="ym + ptIndex">
            <jc-form-item label="APP域名：">
              <p>
                {{ pt.ymXx.length && pt.ymXx.map(item => item.ym).join(',') | limiteStr(50) }}
              </p>
            </jc-form-item>
          </jc-col>
        </template>
      </template>

      <jc-col :span="8">
        <jc-form-item label="服务负责人证件类型：">
          <p>
            {{ siteData && siteData.identityType | identityTypeFilter(identityTypeOptions) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="服务负责人证件号码：">
          <p>{{ siteData && siteData.identityNo | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="关联的京东云服务：">
          <p>
            {{ siteData && siteData.jcloudServiceType | listFilter(SERVICE_TYPE) }}：{{
              siteData && siteData.serviceValue
            }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="手机号码：">
          <p>{{ siteData && siteData.mobile | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="应急电话：">
          <p>{{ siteData && siteData.emergencyCall | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item :label="siteData && siteData.appServiceType === 'web' ? '网站服务内容：' : 'APP服务内容：'">
          <p>{{ showSiteTypes(siteData.siteTypes) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="语言类别：">
          <p>
            {{ siteData && siteData.siteLanguage | languageFilter(languageOptions) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="服务备注：">
          <p>{{ siteData && siteData.remark | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
    </jc-row>
  </jc-form>
</template>
<script>
import { SERVER_CONTENT_TYPE } from '@/views/service/constant.js'
import profileMixins from '@/mixins/profile.mixins.js'
// import { filterArray } from '@/utils'
import { SERVICE_TYPE } from '@/views/service/constant'

export default {
  props: {
    siteData: Object,
    infoData: Object
  },
  components: {},
  mixins: [profileMixins],
  data() {
    return {
      recordSiteTypeEnum: SERVER_CONTENT_TYPE,

      SERVICE_TYPE
    }
  },
  watch: {},
  computed: {
    domainData() {
      return this.infoData.domainInfo.filter(domain => {
        return domain.siteId === this.siteData.id
      })
    },
    appPtList() {
      let ptList = []
      if (this.siteData.appServiceType === 'app' && this.siteData.appInfoVo && this.siteData.appInfoVo.appPt) {
        if (
          this.siteData.appInfoVo.appPt.azPt &&
          this.siteData.appInfoVo.appPt.azPt.ptInfo.length > 0 &&
          this.siteData.appInfoVo.appPt.azPt.ptInfo[0].md5Key
        ) {
          ptList.push({
            type: 'azPt',
            ptInfo: this.siteData.appInfoVo.appPt.azPt.ptInfo,
            ymXx: this.siteData.appInfoVo.appPt.azPt.ymXx
          })
        }
        if (
          this.siteData.appInfoVo.appPt.iosPt &&
          this.siteData.appInfoVo.appPt.iosPt.ptInfo.length > 0 &&
          this.siteData.appInfoVo.appPt.iosPt.ptInfo[0].md5Key
        ) {
          ptList.push({
            type: 'iosPt',
            ptInfo: this.siteData.appInfoVo.appPt.iosPt.ptInfo,
            ymXx: this.siteData.appInfoVo.appPt.iosPt.ymXx
          })
        }
        if (
          this.siteData.appInfoVo.appPt.hmPt &&
          this.siteData.appInfoVo.appPt.hmPt.ptInfo.length > 0 &&
          this.siteData.appInfoVo.appPt.hmPt.ptInfo[0].md5Key
        ) {
          console.log('hmPt--------')
          ptList.push({
            type: 'hmPt',
            ptInfo: this.siteData.appInfoVo.appPt.hmPt.ptInfo,
            ymXx: this.siteData.appInfoVo.appPt.hmPt.ymXx
          })
        }
        if (
          this.siteData.appInfoVo.appPt.bbPt &&
          this.siteData.appInfoVo.appPt.bbPt.ptInfo.length > 0 &&
          this.siteData.appInfoVo.appPt.bbPt.ptInfo[0].md5Key
        ) {
          ptList.push({
            type: 'bbPt',
            ptInfo: this.siteData.appInfoVo.appPt.bbPt.ptInfo,
            ymXx: this.siteData.appInfoVo.appPt.bbPt.ymXx
          })
        }
        if (
          this.siteData.appInfoVo.appPt.linuxPt &&
          this.siteData.appInfoVo.appPt.linuxPt.ptInfo.length > 0 &&
          this.siteData.appInfoVo.appPt.linuxPt.ptInfo[0].md5Key
        ) {
          ptList.push({
            type: 'linuxPt',
            ptInfo: this.siteData.appInfoVo.appPt.linuxPt.ptInfo,
            ymXx: this.siteData.appInfoVo.appPt.linuxPt.ymXx
          })
        }
        if (this.siteData.appInfoVo.appPt.otherPt && this.siteData.appInfoVo.appPt.otherPt.length > 0) {
          this.siteData.appInfoVo.appPt.otherPt.forEach(otherPtItem => {
            if (otherPtItem.ptmc) {
              ptList.push(otherPtItem)
            }
          })
        }
      }
      return ptList
    }
  },
  mounted() {},
  created() {},
  filters: {},
  methods: {
    getPtName(pt) {
      if (pt.type === 'azPt') {
        return '安卓'
      }
      if (pt.type === 'iosPt') {
        return 'iOS'
      }
      if (pt.type === 'hmPt') {
        return '鸿蒙'
      }
      if (pt.type === 'bbPt') {
        return '黑莓'
      }
      if (pt.type === 'linuxPt') {
        return 'linux'
      }
      return `其他平台${pt.ptmc || ''}`
    },
    getCsName(sdkCsId) {
      return this.sdkCsOptions.find(x => x.gjId === sdkCsId)?.localName || ''
    },
    getFwlxName(sdkFwlxId) {
      return this.sdkFwlxOptions.find(x => x.gjId === sdkFwlxId)?.localName || ''
    },

    showSiteTypes(siteTypes) {
      if (!siteTypes) {
        return '--'
      }
      if (typeof siteTypes === 'string' || siteTypes instanceof String) {
        siteTypes = siteTypes.split(',')
      }
      if (this.siteData.appServiceType === 'app') {
        let namesMap = {}
        this.appTypeOptions.map(item => {
          namesMap[item.gjId] = item.localName
        })
        return siteTypes.map(t => namesMap[t]).join(',')
      }
      let namesMap = {}
      SERVER_CONTENT_TYPE.map(item => {
        namesMap[item.value] = item.label
      })
      return siteTypes.map(t => namesMap[parseInt(t)]).join(',')
    }
  }
}
</script>
<style scoped>
.paltform-type {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin: 0 0 16px 0;
}
</style>
